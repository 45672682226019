export const englishWords = {
    yes: "Yes",
    no: "No",
    previous: "Previous",
    next: "Next",
    noResult: "No results",
    missionAcceptedOnAt: "Shift accepted on __VAR1 at __VAR2",
    phone: "Phone",
    noNumber: "No number",
    search: "Search",
    allCheckboxes: "Select all",
    all: "All",
    pages: "pages",
    weAreConnected: "we are connected",
    languages: "languages",
    contact: "Contact",
    allRightsReserved: "All rights reserved.",
    TermsOfService: "General conditions of use",
    customerPartners: "Customers/Partners",
    solution: "Solution",
    about: "About",
    blog: "Blog",
    whitePapers: "White papers",
    connection: "Sign in",
    mstaffSolution: "MSTAFF",
};
