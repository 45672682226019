export const germanWords = {
    yes: "Ja",
    no: "Nein",
    previous: "Früher",
    next: "Folgende",
    noResult: "Kein Ergebnis",
    missionAcceptedOnAt: "Schicht übernommen am __VAR1 um __VAR2",
    phone: "Telefon",
    noNumber: "Keine Nummer",
    search: "Suchen",
    allCheckboxes: "Alles auswählen",
    all: "Alle",
    pages: "Seiten",
    weAreConnected: "wir sind verbunden",
    languages: "Sprachen",
    contact: "Kontakt",
    allRightsReserved: "Alle Rechte vorbehalten.",
    TermsOfService: "Nutzungsbedingungen",
    customerPartners: "Kunden/Partner",
    solution: "Lösung",
    about: "Über uns",
    blog: "Blog",
    whitePapers: "Whitepapers",
    connection: "Anmelden",
    mstaffSolution: "MSTAFF",
};
