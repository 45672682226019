import React from "react";
import { Overlay } from "react-bootstrap";
import "../../scss/components/buttons/ButtonWithOverlay.scss";
interface IPropsButtonWithOverlay {
    name: string;
    innerOverlay: JSX.Element;
    text: string | JSX.Element;
    className?: string;
    isDisabled?: boolean;
}
interface IStateButtonWithOverlay {
    show: boolean;
}

class ButtonWithOverlay extends React.Component {
    props: IPropsButtonWithOverlay = {
        innerOverlay: <span />,
        name: "",
        text: ""
    };
    state: IStateButtonWithOverlay = {
        show: false
    };
    static defaultProps: Partial<IPropsButtonWithOverlay> = {
        className: ""
    };
    render(): JSX.Element {
        return (
            <div className="is-position-relative" ref={this.props.name}>
                <button
                    className={"text-align-left button-with-overlay ".concat(
                        this.props.className && this.props.className !== ""
                            ? this.props.className
                            : "btn btn-block btn-filter"
                    )}
                    style={{ backgroundColor: this.props.isDisabled ? "#e9ecef" : "" }}
                    type="button"
                    onClick={(): void => {
                        if (!this.props.isDisabled) {
                            this.setState({
                                show: !this.state.show
                            });
                        }
                    }}
                >
                    {this.props.text}
                </button>
                <Overlay
                    show={this.state.show}
                    // @ts-ignore
                    // eslint-disable-next-line
                    container={this.refs[this.props.name]}
                    // @ts-ignore
                    // eslint-disable-next-line
                    target={this.refs[this.props.name]}
                    rootClose={true}
                    placement="bottom-start"
                    onHide={(): void =>
                        this.setState({
                            show: false
                        })
                    }
                >
                    <div className="mypopover">
                        <div className="mypopover-menu has-margin-top is-medium container">
                            {this.props.innerOverlay}
                        </div>
                    </div>
                </Overlay>
            </div>
        );
    }
}

export default ButtonWithOverlay;
