import React from "react";
import { isMobile } from "../../modules/toolbox/devices";
const URL_FAQ = "https://hublohelp.zendesk.com/",
    URL_IMAGES = "https://medgostatic.s3.eu-west-3.amazonaws.com/";
import "./Footer.scoped.scss";
import { EAvailableLang } from "../..";
import chooseLanguage from "../../modules/toolbox/utils";
import SelectLanguage from "../../component/select/SelectLanguage.component";

const moment = require("moment");

interface FooterLandingProps {
    emailText: string;
    urlBase?: string;
    currentLang: EAvailableLang;
    lang: {
        whitelist?: number[];
        customerPartners: string;
        solution: string;
        about: string;
        contact: string;
        blog: string;
        whitePapers: string;
        faq: string;
        pages: string;
        weAreConnected: string;
        languages: string;
        allRightsReserved: string;
        TermsOfService: string;
        language: string;
        noResult: string;
        mstaffSolution: string;
    };
    handleChangeLang: (lang: EAvailableLang) => void;
}

const FooterLanding: React.FC<FooterLandingProps> = ({
    emailText,
    urlBase,
    currentLang,
    lang,
    handleChangeLang
}) => {
    let mail = <span>{emailText}</span>;

    /* fix for android standard browser which sometimes wrongly detects phone numbers somewhere */
    if (isMobile()) {
        mail = (
            <a href={"mailto:" + emailText}>
                <span>{emailText}</span>
            </a>
        );
    }

    const leftElements: JSX.Element[] = [
            currentLang === EAvailableLang.en ?
                <a key="blog" href="https://www.hublo.com/en/clients">
                    {chooseLanguage(lang, currentLang).customerPartners}
                </a>: null,
        <a key="solutions" href={`https://www.hublo.com/${currentLang}/solution`}>
            {chooseLanguage(lang, currentLang).solution}
        </a>,
        <a key="about" href={`https://www.hublo.com/${currentLang}/about`}>
            {chooseLanguage(lang, currentLang).about}
        </a>,
        <a key="contact" href={`https://www.hublo.com/${currentLang}/contact`}>
            {chooseLanguage(lang, currentLang).contact}
        </a>,
        [EAvailableLang.fr, EAvailableLang.de].includes(currentLang) ? (
            <a key="blog" href={`https://www.hublo.com/${currentLang}/blog`}>
                {chooseLanguage(lang, currentLang).blog}
            </a>
        ) : null,
        [EAvailableLang.fr, EAvailableLang.de, EAvailableLang.es].includes(currentLang) ? (
            <a key="resources" href={`https://www.hublo.com/${currentLang}/resources`}>
                {chooseLanguage(lang, currentLang).whitePapers}
            </a>
        ) : null,
        currentLang === EAvailableLang.fr ? (
            <a key="mstaff" href={`https://www.hublo.com/${currentLang}/mstaff`}>
                {chooseLanguage(lang, currentLang).mstaffSolution}
            </a>
        ) : null
    ].filter(element => element !== null) as JSX.Element[];
    const contactElements: JSX.Element[] = [
        <div key="email">{mail}</div>,
        <a key="faq" href={`${URL_FAQ}hc/${currentLang}`} target="_blank">
            {chooseLanguage(lang, currentLang).faq}
        </a>
    ];
    const weAreConnectedElements = [
        <a key="twitter" href="https://twitter.com/_medGo_" target="_blank">
            <i className="fa-3x fab fa-twitter-square" />
        </a>,
        <a key="facebook" href="https://www.facebook.com/wearehublo" target="_blank">
            <i className="fa-3x fab fa-facebook-square" />
        </a>,
        <a key="linkedin" href="https://www.linkedin.com/company/medgo-rempla" target="_blank">
            <i className="fa-3x fab fa-linkedin" />
        </a>
    ];
    return (
        <div className="main-footer is-landing">
            <div className="background-black">
                <div className="container">
                    <div className="row padding-top-m">
                        <div className="col-4 text-center">
                            <img className="img-fluid" src={URL_IMAGES + "medgo1.svg"} alt="Trust" />
                        </div>
                        <div className="col-4 col-md-3 offset-md-1 d-none d-sm-block">
                            <Liste
                                title={chooseLanguage(lang, currentLang).pages.toUpperCase()}
                                elements={leftElements}
                            />
                        </div>
                        <div className="col-4 col-md-3 offset-md-1 col-8">
                            <Liste
                                title={chooseLanguage(lang, currentLang).contact.toUpperCase()}
                                elements={contactElements}
                            />
                            <Liste
                                title={chooseLanguage(lang, currentLang).weAreConnected.toUpperCase()}
                                style={{ display: "inline", marginTop: 15, marginRight: 15, color: "#F6593B" }}
                                elements={weAreConnectedElements}
                            />
                            <Liste
                                title={chooseLanguage(lang, currentLang).languages.toUpperCase()}
                                style={{
                                    color: "#F6593B",
                                    display: "inline",
                                    marginRight: 15,
                                    marginTop: 15
                                }}
                                elements={[
                                    <SelectLanguage
                                        whitelist={lang.whitelist}
                                        lang={lang}
                                        currentLang={currentLang}
                                        handleChangeLang={handleChangeLang}
                                    />
                                ]}
                            />
                        </div>
                        <div className="clearfix" />
                        <div className="primary_section text-center">
                            <ul className="list-inline list-unstyled">
                                <li className="list-inline-item margin-top-m-fixe text-9">
                                    © {moment().format("YYYY")}. {chooseLanguage(lang, currentLang).allRightsReserved}
                                </li>
                                <li className="list-inline-item margin-top-m-fixe text-9">
                                    <a href={`https://www.hublo.com/${currentLang}/terms-of-use`}>
                                        {chooseLanguage(lang, currentLang).TermsOfService}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface LinkProps {
    text: JSX.Element;
    style?: React.CSSProperties;
}

const Link: React.FC<LinkProps> = ({ text, style }) => (
    <div style={style} className={`text-15 text-open-sans white-text text-bold margin-top-s`}>
        {text}
    </div>
);

interface ListeProps {
    title: string;
    elements: JSX.Element[];
    style?: React.CSSProperties;
}

const Liste: React.FC<ListeProps> = ({ title, elements, style }) => (
    <div>
        <div className="text-15 text-open-sans orange-text text-bold margin-top-s">{title}</div>
        {elements.map((e, i) => (
            <Link key={i} text={e} style={style} />
        ))}
    </div>
);

export default FooterLanding;
